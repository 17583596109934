<p-dialog
  header=""
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [ngStyle]="dialogStyle"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <div class="content-container" *ngIf="!isLoading">
    <!-- Título del préstamo -->
    <div class="flex justify-content-between align-items-center">
      <h2 class="text-orange-600">Vacaciones</h2>
    
    </div>
    
    <!-- Estado de la solicitud -->
    <div class="progress-bar">
      <div class="line-background"></div>
      <div
        class="line-active"
        [style.width.%]="
          status === 'aprobado' || status === 'rechazado'
            ? ((currentStep - 1) / (steps.length - 1)) * 99
            : (currentStep / (steps.length - 1)) * 99
        "
        [ngClass]="{
          'line-accepted': status === 'aprobado',
          'line-rejected': status === 'rechazado'
        }"
      ></div>

      <div *ngFor="let item of visibleSteps; let i = index" class="step">
        <div class="circle-container">
          <div
            class="circle"
            [ngClass]="{
              active:
                i < currentStep ||
                (status === 'rechazado' && i < currentStep - 1),
              accepted: status === 'aprobado',
              rejected: i === currentStep - 1 && status === 'rechazado'
            }"
          ></div>
        </div>

        <div class="label">
          {{
            status === "aprobado" && i === 3
              ? ""
              : i === 2 && status === "rechazado"
              ? "Rechazado"
              : item.label
          }}
        </div>
      </div>
    </div>
    
    <!-- Detalles de la solicitud -->
    <div class="mt-4">
      <div class="flex flex-column gap-5">
        <div>
          <p class="text-orange-400">Inicio de vacaciones</p>
          <p>{{ dateStart }}</p>
        </div>

        <div>
          <p class="text-orange-400">Fin de vacaciones</p>
          <p> {{ dateEnd }} </p>
        </div>
      </div>

      <p class="text-orange-400">Mensaje</p>
      <p style="width: 100%;"> {{ message == null ? 'No cuenta con descripción de solicitud.' : message }} </p>
    </div>

    <hr />
    <!-- Mensaje de administración -->
    <div class="mt-4">
      <p class="text-orange-400">Mensaje de administración</p>
      <p style="width: 100%;">
        {{ adminMessage == null ? 'Su solicitud ha sido recibida, en breve será atendida por un administrador.' : adminMessage }}
      </p>
    </div>

    <!-- Botón de aceptar -->
    <div class="submit-button">
      <button
        pButton
        type="button"
        label="ACEPTAR"
        (click)="onButtonClick()"
      ></button>
    </div>
  </div>
</p-dialog>
