import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentSlipResponseDTO } from '../../interfaces/payment-slips.interface';

@Injectable({
  providedIn: 'root',
})
export class SolicitudeService {
  private apiUrl = `${environment.apiUrl}/solicitudes`;

  constructor(private http: HttpClient) {}

  postSolicitudesByCompany(filter: {
    search: string;
  }): Observable<PaymentSlipResponseDTO[]> {
    return this.http.post<PaymentSlipResponseDTO[]>(
      this.apiUrl + '/listSolicitudeByEmployee',
      filter
    );
  }

  getDetailSolicitudeByDetailSolicitudeId (id: number): Observable<any>   {
    return this.http.get<any>(`${this.apiUrl}/detailSolicitudeByDetailSolicitudeId/${id}`);
  }

  deleteSolicitude (solicitudeId: number, typeSolicitudeId: number): Observable<any>   {
    return this.http.delete<any>(`${this.apiUrl}/deleteSolicitude/${solicitudeId}/${typeSolicitudeId}`);
  }
}
